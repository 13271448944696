import React from 'react';
import { RiDeleteBin5Line, RiPencilFill } from "react-icons/ri";
import { AiFillStar } from "react-icons/ai";
import { fetchData } from '../services/ApiService';
import PrimaryButton from './PrimaryButton';
import { useSelector } from 'react-redux';


const Wish = ({
    id = 0,
    title,
    link,
    host,
    note,
    price,
    people = [],
    color,
    isImportant,
    buyAsGroup,
    setLoading,
    isOwner = false,
    editWish,
}: any) => {
    const userId = useSelector((state: any) => state.auth.id);

    const deleteWish = async () => {
        setLoading(true);
        await fetchData("/wishes/" + id, {
            method: "DELETE"
        });
        window.location.reload();
    }

    const toggleBuyAsGroup = async (id: any) => {
        setLoading(true);
        await fetchData("/wish/buy-as-group/" + id, {
            method: "POST"
        });
        window.location.reload();
    }

    const addMeToWishAction = async (id: any) => {
        setLoading(true);
        await fetchData("/wish/add-me/" + id, {
            method: "POST"
        });
        window.location.reload();
    }

    const deleteMeFromWishAction = async (id: any) => {
        setLoading(true);

        if (people.length === 1) {
            await toggleBuyAsGroup(id);
        }

        await fetchData("/wish/delete-me/" + id, {
            method: "POST"
        });
        window.location.reload();
    }

    let wishIsFree = people.length === 0;
    let alreadyGivingTheGift = false;
    people.forEach((person: any) => {
        if (person.id === userId) {
            alreadyGivingTheGift = true;
        }
    });

    return <div className={"md:basis-1/2 lg:basis-1/3 p-2 relative w-full"}>
        <div className={"bg-gray-200 justify-between inline-block rounded border-2 w-full"  
        + (people.length > 0 ? " border-green-600 border-2" : "") 
        + (isImportant ? " border-yellow-600" : "")}>

            {isImportant && <div className={"absolute -top-1 -right-1"}>
                <AiFillStar size={30} color={"#ca8a04"} />
            </div>}

            <div className={"flex flex-col items-start justify-between px-5 py-2"}>
                <h4 className={"text-2xl font-bold text-left w-4/5"} style={{overflowWrap: "break-word"}}>{title}</h4>
                {price && <div className={"text-xl"}>{price} €</div>}
            </div>

            {isOwner && <div className='absolute right-4 top-4 p-1.5 border-2 rounded-full flex justify-center items-center text-center hover:cursor-pointer'>
                <RiDeleteBin5Line size={20} onClick={deleteWish} />
            </div>}

            {isOwner && <div className='absolute right-11 top-4 p-1.5 border-2 rounded-full flex justify-center items-center text-center hover:cursor-pointer'>
                <RiPencilFill size={20} onClick={editWish} />
            </div>}


            {(note || link) && <hr className={"w-full border-1 border-black"} />}
            {host && <div className={"px-5 pt-3 pb-1 text-right"}>
                Verfügbar bei: <div className={"inline-block px-2 rounded " + color}>{host}</div>
            </div>}

             {note &&
                <div className={"mx-4 my-2 bg-blue-300 p-2 rounded"}>
                    <b>Anmerkung:</b> {note}
                </div>
            }

            {link
                && <div
                    onClick={() => window.open(link, '_blank')}
                    className={"hover:cursor-pointer bg-green-700 mx-4 my-2 text-center rounded p-2"}
                >
                    Zum Artikel
                </div>

            }

            {!isOwner && <div className={"px-5 py-2"}>
                {wishIsFree && <div className={"flex flex-row justify-between pb-2"}>
                    <div className={"text-lg"}>
                        Dieser Wunsch ist noch frei.
                    </div>
                </div>}
                {!wishIsFree && <h4 className={"mb-1"}>Ausgewählt von:</h4>}
                {people.map((person: any, key: number) => {
                        return <div key={key} className={"flex flex-row justify-between pb-2"}>
                            <div className={"text-lg"}>
                                - {person.attributes.username}
                            </div>
                        </div>;
                })}

                {buyAsGroup && <div className='py-2'>
                    Bei diesem Wunsch wird nach Beteiligung gesucht.
                </div>}

                <div className={"w-full flex flex-row justify-between pb-2"}>
                    {wishIsFree 
                        ? <PrimaryButton 
                            value="Kaufe ich"
                            action={() => addMeToWishAction(id)}
                        />
                        : alreadyGivingTheGift
                            ? <PrimaryButton 
                                    status="error"
                                    value="Kaufe ich doch nicht"
                                    action={() => deleteMeFromWishAction(id)}
                            />
                            : <PrimaryButton
                                status="warning"
                                value="Ich beteilige mich"
                                action={() => addMeToWishAction(id)}
                            />
                    }
                </div>
                {alreadyGivingTheGift && <div className={"w-full flex flex-row justify-between pb-2"}>
                    {buyAsGroup 
                    ? <PrimaryButton 
                        status={"warning"}
                        value="Beteiligung nicht erwünscht"
                        action={() => toggleBuyAsGroup(id)}
                    />
                    : <PrimaryButton 
                        value="Beteiligung erwünscht"
                        action={() => toggleBuyAsGroup(id)}
                    />}
                </div>}

            </div>}
        </div>
    </div>
}

export default Wish;