// show login or registration possibility
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import FormRow from "../../components/FormRow";
import PrimaryButton from "../../components/PrimaryButton";
import { login } from "../../globals/authReducer";
import { fetchData } from "../../services/ApiService";

const Register = () => {
    const [registerUser, setRegisterUser] = useState("");
    const [registerEmail, setRegisterEmail] = useState("");
    const [registerPassword, setRegisterPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const registerAction = async () => {
        setLoading(true);
        setError("");
        return fetchData('/auth/local/register', {
            timeout: 5000,
            data: {
                email: registerEmail,
                username: registerUser,
                password: registerPassword
            }
        }, false)
        .then((data) => {
            setError("");
            dispatch(login({payload: {identifier: registerUser, token: data.jwt, id: data.user.id}}));
            navigate("/");
        })
        .catch((err) => {
            setError("Leider konnte kein Account erstellt werden. Sind die Daten korrekt?")
        })
        .finally(() => {
            setLoading(false);
        });
    };

    return <div className={"w-full flex justify-center"}>
        <div className="wrapper flex-col w-80 lg:w-96 mt-20">
            <h3 className="text-2xl text-center text-emerald-700 mb-4">Registrieren bei wishlist.digital</h3>
            <div className="login-wrapper">
                <h2 className="text-lg pb-2">Registrierung</h2>
                <div className="rounded-lg p-4 bg-slate-100 shadow">
                    <FormRow 
                        name="registerEmail"
                        label="E-Mailadresse"
                        value={registerEmail}
                        setter={setRegisterEmail}
                        placeholder="marco@email.de"
                        type="email"
                    />
                    <FormRow 
                        name="registerUser"
                        label="Username"
                        value={registerUser}
                        setter={setRegisterUser}
                        placeholder="Marco"
                    />
                    <FormRow 
                        name="registerPassword"
                        label="Passwort"
                        value={registerPassword}
                        setter={setRegisterPassword}
                        placeholder="Geh3imn1s5"
                        type="password"
                    />
                    <div className="input-row pt-4 w-full">
                        <PrimaryButton 
                            disabled={loading || registerEmail === "" || registerUser === "" || registerPassword === ""}
                            value="Registrieren"
                            action={registerAction}
                        />
                    </div>
                </div>
                {error !== "" && <div className="max-w-md inline-text break-words text-center text-red-500 py-2 text-md">{error}</div>}
                <div className="mt-2 text-center">Doch schon einen Account? Jetzt <div onClick={() => navigate("/")} className="inline-block text-green-600 hover:cursor-pointer">anmelden</div></div>
            </div>
        </div>
    </div>
}

export default Register;