import { Provider } from 'react-redux';
import store, {persistor} from "./globals/store.js";
import Wishlist from "./pages/App/Wishlist";
import JoinWishlist from "./pages/App/JoinWishlist";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import Overview from "./pages/App/Overview";
import {
    BrowserRouter,
    Route,
    Routes,
} from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react'
import RequireAuth from "./pages/Auth/RequireAuth";
import NotFound from "./pages/App/NotFound";

function App() {
    return <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />

                    <Route element={<RequireAuth />}>
                        <Route path="/wishlist/join" element={<JoinWishlist />} />
                        <Route path="/wishlist/join/:id/:hash" element={<JoinWishlist />} />
                        <Route path="/wishlist/:wishlistId" element={<Wishlist />} />
                        <Route path="/" element={<Overview />} />
                    </Route>

                    <Route path={"*"} element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </PersistGate>
    </Provider>;
}

export default App;
