// show all available wishlists or join one
// show login or registration possibility
import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from "react-router";
import FormRow from "../../components/FormRow";
import PrimaryButton from "../../components/PrimaryButton";
import { fetchData } from "../../services/ApiService";
import Modal from "../../components/Modal";
import { RiDeleteBin6Line } from "react-icons/ri";
import {logout} from "../../globals/authReducer";

const Overview = () => {
    const [loading, setLoading] = useState(false);
    const userId = useSelector((state: any) => state.auth.id);
    const navigate = useNavigate();
    const [ownedWishlists, setOwnedWishlists] = useState([]);
    const [wishlists, setWishlists] = useState([]);
    const [wishlistName, setWishlistName] = useState("");
    const identifier = useSelector((state: any) => state.auth.identifier);
    const dispatch = useDispatch();

    useEffect(() => {
        loadOverview();
    }, []);

    const logoutUser = () => {
        dispatch(logout());
    }

    const loadOverview = async () => {
        setLoading(true);
        return fetchData('/users/' + userId + '?populate=wishlists&populate=ownedWishlists&populate=owner', {
            method: "GET",
            data: null
        })
        .then((data) => {
            setOwnedWishlists(data.ownedWishlists);
            setWishlists(data.wishlists);
        })
        .catch((err) => {
            navigate("/");
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const createAction = async () => {
        setLoading(true);
        return fetchData('/wishlists', {
            data: {
                data: {
                    title: wishlistName,
                    owner: userId
                }
            }
        })
        .then((data) => {
            navigate("/wishlist/" + data.data.id);
        })
        .catch((err) => {
            console.error(err);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const deleteWishlist = (id: number) => {
        setLoading(true);
        return fetchData('/wishlists/' + id, {
            method: "DELETE"
        })
        .then((data) => {
            window.location.reload();
        })
        .catch((err) => {
            console.error(err);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const WishlistTable = ({wishlistData, isOwned = false}: any) => <div className="bg-white">
        {wishlistData.map((wishlist: any, key: number) => {
            return <div key={key} className="flex flex-row justify-between bg-gray-50 mb-2 p-5">
                <h2>{wishlist.title}</h2>
                <div className="flex flex-row">
                <div 
                    onClick={() => { navigate("/wishlist/" + wishlist.id);}}
                    className="text-blue-600 hover:cursor-pointer hover:text-blue-700 active:text-blue-800"
                >
                    Zur Liste
                </div>
                {isOwned && <div className="ml-4 hover:cursor-pointer" onClick={() => deleteWishlist(wishlist.id)}>
                    <RiDeleteBin6Line size={20} />
                </div>}
                </div>
            </div>;
        })}
    </div>;

    return <div className="flex justify-center">
        <div className="w-80 lg:w-2/3">
            <div className="flex flex-row justify-end">
                <div className={"cursor-pointer"} onClick={logoutUser}>{identifier}: Abmelden</div>
            </div>
            <div>
                <div className="mt-10 mb-2 flex justify-between flex-wrap lg:flex-none">
                    <div className="flex flex-col-reverse"><div className="text-xl mb-2 lg:mb-0">Deine Wunschlisten:</div></div>

                    <div className="flex flex-row">
                        <div onClick={() => navigate("/wishlist/join")} className="hover:cursor-pointer bg-green-50 border-2 border-green-500 px-4 py-2 mr-4">
                            Liste beitreten
                        </div>
                        <div className="bg-green-500 px-4 py-2 border-2 border-green-500">
                            <Modal
                                title="Neue Wunschliste"
                                buttonText="Neue Liste +"
                            >
                                <FormRow 
                                    label="Titel"
                                    name="wishlistName"
                                    value={wishlistName}
                                    setter={setWishlistName}
                                    placeholder="Lindas Geburtstag"
                                />

                                <div className="input-row pt-4 w-full">
                                    <PrimaryButton 
                                        disabled={loading || wishlistName === ""}
                                        value="Liste erstellen"
                                        action={createAction}
                                    />
                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
                {ownedWishlists.length > 0
                    ? <WishlistTable wishlistData={ownedWishlists} isOwned={true} />
                    : <div className="p-4 text-center bg-gray-50">Erstelle deine erste Wunschliste.</div>
                }
            </div>
            <div>
                <div className="mt-10 mb-2 flex justify-between">
                    <div className="flex flex-col-reverse"><div className="text-xl">Wunschlisten von Anderen:</div></div>
                </div>
                {wishlists.length > 0 
                    ? <WishlistTable wishlistData={wishlists} />
                    : <div className="p-4 text-center bg-gray-50">Tritt einer Wunschliste bei, damit Sie hier auftaucht.</div>
                }
            </div>
        </div>
    </div>;
}

export default Overview;