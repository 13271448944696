import axios from 'axios';
import store from '../globals/store';

const BASE_URL = process.env.REACT_APP_API_URL;

export function register(email, username, password) {
    return new Promise(async (resolve, reject) => {
        try {
            let result = await fetchData({
                url: '/auth/local/register',
                data: {
                    email,
                    username,
                    password
                },
                timeout: 5000,
                isPost: true
            });

            resolve(result);
        } catch (error) {
            reject(error);
        }
    });
}

export function fetchData(url, options, useAuth = true) {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            };

            const state = store.getState();
            if (state.auth.token && useAuth) {
                headers['Authorization'] = 'Bearer ' + state.auth.token;
            }

            const result = await axios({
                url: BASE_URL + url,
                timeout: options.timeout ?? 5000,
                method: options.method ?? 'POST',
                headers: headers,
                data: options.data ?? []
            });

            const data = await result.data;
            if (result.status === 200) {
                resolve(data);
            } else {
                reject(data);
            }
        } catch (error) {
            reject(error);
        }
    });
}