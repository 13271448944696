import React, {useEffect, useState} from 'react';
import Wish from "../../components/Wish";
import {fetchData} from "../../services/ApiService.js";
import {useNavigate, useParams} from "react-router-dom";
import { useSelector } from 'react-redux';
import Modal from '../../components/Modal';
import FormRow from '../../components/FormRow';
import PrimaryButton from '../../components/PrimaryButton';
import { RiFileCopyFill } from "react-icons/ri";

function Wishlist() {
    const [isLoading, setIsLoading] = useState(true);
    const [wishes, setWishes] = useState([]) as any;
    const [isOwner, setIsOwner] = useState(false);
    const userId = useSelector((state: any) => state.auth.id);
    const navigate = useNavigate();
    const { wishlistId } = useParams();
    const [wishId, setWishId] = useState(null);
    const [wishName, setWishName] = useState("");
    const [wishLink, setWishLink] = useState("");
    const [wishPrice, setWishPrice] = useState("");
    const [wishNote, setWishNote] = useState("");
    const [wishImportant, setWishImportant] = useState(false);
    const [wishlistData, setWishlistData] = useState({} as any);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [joinedUsers, setJoinedUsers] = useState([]);

    useEffect(() => {
        loadWishes();
    }, []);

    const clearForm = () => {
        setWishId(null);
        setWishName("");
        setWishLink("");
        setWishPrice("");
        setWishNote("");
        setWishImportant(false);
    }

    const toggleWishImportant = (val: any) => {
        setWishImportant(!wishImportant);
    }

    const editWish = (id: any, wish: any) => {
        setWishId(id);
        setWishName(wish.attributes.title);
        setWishLink(wish.attributes.link);
        if (wish.attributes.price) {
            setWishPrice(wish.attributes.price);
        }
        setWishNote(wish.attributes.note);
        setWishImportant(wish.attributes.isImportant);
        setIsModalOpen(true);
    }

    const updateWishAction = () => {
        setIsLoading(true);
        return fetchData('/wishes/' + wishId, {
            method: "PUT",
            timeout: 5000,
            data: {
                data: {
                    title: wishName,
                    link: wishLink,
                    price: (wishPrice === "" ? null : wishPrice),
                    note: wishNote,
                    wishlist: wishlistId,
                    isImportant: wishImportant
                }
            }
        })
        .then((data) => {
            clearForm();
            setIsModalOpen(false);
            loadWishes();
        })
        .catch((err) => {
            console.error(err);
        })
        .finally(() => {
            setIsLoading(false);
        });
    }

    const changeSortOrder = (event: any = null) => {
        let sorting = event.target.value.split("-");
        let field = sorting[0];
        let order = sorting[1] === "asc" ? 1 : -1;

        wishes.sort((a: any, b: any) => {
            return a.attributes[field] > b.attributes[field] ? order : -1 * order;
        });

        setWishes(Object.values(wishes));
    }

    const createAction = () => {
        setIsLoading(true);
        return fetchData('/wishes', {
            timeout: 5000,
            data: {
                data: {
                    title: wishName,
                    link: wishLink,
                    price: (wishPrice === "" ? null : wishPrice),
                    note: wishNote,
                    wishlist: wishlistId,
                    isImportant: wishImportant
                }
            }
        })
        .then((data) => {
            clearForm();
            setIsModalOpen(false);
            loadWishes();
        })
        .catch((err) => {
            console.error(err);
        })
        .finally(() => {
            setIsLoading(false);
        });
    }

    const toggleModal = (isOpen: any) => {
        setIsModalOpen(isOpen);

        if (isOpen === false) {
            clearForm();
        }
    }

    const loadWishes = async () => {
        let wishes = [];
        try {
            wishes = await fetchData("/wishlists/" + wishlistId, {method: "GET"});
        } catch (err: any) {
            return;
        }

        setWishlistData(wishes.data.attributes);
        setJoinedUsers(Object.values(wishes.data.attributes.users.data));
        if (wishes.data.attributes.owner.data.id === userId) {
            setIsOwner(true);
        }

        let sortedWishes: any = {};
        wishes.data.attributes.wishes.data.forEach((wish: any) => {
            // stupid mistake in api
            try {
                let {host} = new URL(wish.attributes.link);
                wish.attributes.price = parseFloat(wish.attributes.price);
                host = host.replace("www.", "");
                wish.attributes.host = host;
            } catch (err) {}

            sortedWishes[wish.id] = wish;
        });

        let tmpWishes: any = Object.values(sortedWishes);
        tmpWishes.sort((a: any, b: any) => {
            return a.attributes.host > b.attributes.host ? 1 : -1;
        });

        let colors = ["bg-red-600", "bg-orange-600", "bg-amber-600", "bg-yellow-600", "bg-lime-600", "bg-green-600", "bg-emerald-600", "bg-teal-600", "bg-cyan-600", "bg-sky-600", "bg-blue-600", "bg-indigo-600", "bg-violet-600", "bg-purple-600", "bg-fuchsia-600", "bg-pink-600", "bg-rose-600"];
        let previousHost = "";
        let colorIndex = 1;
        tmpWishes.forEach((wish: any, key: number) => {
            if (wish.attributes.host !== previousHost) {
                colorIndex += 1;
                previousHost = wish.attributes.host;
            }

            wish.attributes.color = colors[colorIndex % colors.length];
            tmpWishes[key] = wish;
        })

        setWishes(tmpWishes);
        setIsLoading(false);
    }

    const Loader = () => <div
        className={"text-4xl fixed top-0 left-0 right-0 bottom-0 bg-gray-100 opacity-50 flex justify-center items-center"}
    >
        Loading ...
    </div>;

    const joinedUserNames = joinedUsers.map((user: any) => {
        return user.attributes.username;
    })

    return (
        <div className="App">
            {isLoading 
                ? <Loader />
                : <React.Fragment>
                    <div className={"p-4 bg-gray-100 w-full z-10"}>
                        <div onClick={() => navigate("/")} className={"text-left mb-2 hover:cursor-pointer"}>Zur Übersicht</div>

                        {wishlistData && <div className={"text-3xl font-bold"}>{wishlistData.title}</div>}
                        {wishlistData && <div className={"text-xl mb-4"}>von: {wishlistData.owner.data.attributes.username}</div>}
                        {joinedUsers && <div className=''>Beigetretene User: {joinedUserNames.join(", ")}</div>}
                        {isOwner && <div
                            className={"inline hover:bg-gray-300 active:bg-green-600 hover:cursor-pointer"}
                            onClick={() => navigator.clipboard.writeText(window.location.origin + "/wishlist/join/" + wishlistId + "/" + wishlistData.hash)}
                        >
                            Einladungslink kopieren <RiFileCopyFill className={"inline-block mb-1"} height={20} width={20}/>
                        </div>}
                    </div>

                    <div className={"px-10 pt-4 text-left"}>
                    <div className={"flex flex-row justify-between px-2"}>
                        <div></div>
                        <div className={"flex items-end pb-1 justify-center text-center"}>
                            <select className={"text-center"} onChange={(event) => changeSortOrder(event)}>
                                <option value={"host-asc"}>Anbieter</option>
                                <option value={"title-asc"}>Alphabetisch</option>
                                <option value={"price-asc"}>Preis aufsteigend</option>
                                <option value={"price-des"}>Preis absteigend</option>
                            </select>
                        </div>
                    </div>
                    {isOwner && <div className="bg-green-500 px-4 py-2 m-2">
                        <Modal
                            title="Neuer Wunsch"
                            buttonText="Wunsch hinzufügen +"
                            setIsModalOpen={toggleModal}
                            isModalOpen={isModalOpen}
                        >
                            <FormRow 
                                label="Wunsch"
                                name="wishName"
                                value={wishName}
                                setter={setWishName}
                                placeholder="Kinto CKI Tassen"
                            />

                            <FormRow
                                label="Notiz"
                                name="wishNote"
                                value={wishNote}
                                setter={setWishNote}
                                placeholder="Variante: schwarz/grau"
                            />

                            <FormRow 
                                label="Link zum Online-Shop"
                                name="wishLink"
                                value={wishLink}
                                setter={setWishLink}
                                placeholder="https://wwww.coffeecircle.de"
                            />

                            <FormRow 
                                label="Preis"
                                name="wishPrice"
                                value={wishPrice}
                                setter={setWishPrice}
                                placeholder="49,99"
                                type={"number"}
                            />

                            <FormRow 
                                label="Lieblingswunsch"
                                name="wishImportant"
                                value={wishImportant}
                                setter={toggleWishImportant}
                                type={"checkbox"}
                            />                            

                            <div className="input-row pt-4 w-full">
                                <PrimaryButton 
                                    disabled={isLoading || wishName === ""}
                                    value={wishId ? "Wunsch updaten" : "Wunsch hinzufügen"}
                                    action={wishId ? updateWishAction : createAction}
                                />
                            </div>
                        </Modal>
                    </div>}

                    <div className={"flex flex-col md:flex-row justify-items-start flex-wrap"}>
                        {wishes.map((wish: any, key: number) => {
                            return <Wish
                                isOwner={isOwner}
                                key={key}
                                id={wish.id}
                                note={wish.attributes.note}
                                title={wish.attributes.title}
                                link={wish.attributes.link}
                                price={wish.attributes.price}
                                host={wish.attributes.host}
                                color={wish.attributes.color}
                                buyAsGroup={wish.attributes.buyAsGroup}
                                isImportant={wish.attributes.isImportant}
                                people={wish.attributes?.users?.data}
                                setLoading={setIsLoading}
                                editWish={() => editWish(wish.id, wish)}
                            />
                        })}
                    </div>
                </div></React.Fragment>
            }
        </div>
    );
}

export default Wishlist;
