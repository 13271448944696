import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    identifier: null,
    id: null
  },
  reducers: {
    login: (state, action) => {
      state.token = action.payload.token;
      state.identifier = action.payload.identifier;
      state.id = action.payload.id;
    },
    logout: (state) => {
      state.token = null;
      state.identifier = null;
      state.id = null;
    }
  },
})

export const { login, logout } = authSlice.actions

export default authSlice.reducer;