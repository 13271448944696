import React from "react";

const PrimaryButton = ({value, action, disabled = false, status = "success"}: any) => {

    let coloring = "border-green-400 bg-green-50 hover:bg-green-400 active:text-white active:bg-green-600 active:border-green-600";
    if (status === "warning") {
        coloring = "border-orange-400 bg-orange-50 hover:bg-orange-400 active:text-white active:bg-orange-600 active:border-orange-600";
    }

    if (status === "error") {
        coloring = "border-red-400 bg-red-50 hover:bg-red-400 active:text-white active:bg-red-600 active:border-red-600";
    }

    return <input 
        disabled={disabled}
        type="button" 
        onClick={action} 
        value={value}
        className={"border-2 disabled:bg-gray-400 disabled:border-gray-500 hover:cursor-pointer w-full px-2 py-1 " + coloring}
    />
}

export default PrimaryButton;