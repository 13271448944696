import React from "react";

const FormRow = ({label, value, setter, placeholder, name, disabled = false, type = "text"}: any) => {
    return <div className="input-row pt-2 pb-2">
        <label htmlFor={name}>
            {label}:
        </label>
        <input 
            disabled={disabled}
            name={name}
            type={type} 
            value={value} 
            checked={value}
            onChange={e => setter(e.target.value)} 
            placeholder={placeholder}
            className="w-full rounded mt-2 px-2 py-1"
        />
    </div>;
}

export default FormRow;