import React, { useState } from "react";

const Modal = ({title, buttonText, children, isModalOpen = null, setIsModalOpen = null}: any) => {
    const [isOpen, setIsOpen] = useState(false);

    return <div className="modal-wrapper">
        <div onClick={() => {(setIsModalOpen !== null ? setIsModalOpen(true) : setIsOpen(true))}} className={"hover:cursor-pointer"}>
            {buttonText}
        </div>
       {(isOpen || isModalOpen) && <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center align-middle z-20">
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-30"></div>
            <div className="wrapper flex-col w-80 lg:w-96 mt-20 z-10">
                <div className="rounded-lg p-4 bg-slate-100 shadow">
                    <div className="flex flex-row justify-between align-middle">
                        <h2 className="text-lg pb-2">{title}</h2>
                        <div 
                            onClick={() => {setIsOpen(false); setIsModalOpen(false); }} 
                            className="hover:cursor-pointer"
                        >
                            Schließen
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </div>}
    </div>
}

export default Modal;