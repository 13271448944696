// show login or registration possibility
import React, { useState } from "react";
import { useNavigate } from "react-router";
import FormRow from "../../components/FormRow";
import PrimaryButton from "../../components/PrimaryButton";
import { fetchData } from "../../services/ApiService";
import { login } from "../../globals/authReducer";
import { useDispatch } from "react-redux";
import {useLocation} from "react-router-dom";

const Login = () => {
    const [loginUser, setLoginUser] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const loginAction = async () => {
        setLoading(true);
        setError("");
        return fetchData('/auth/local', {
            timeout: 5000,
            data: {
                identifier: loginUser,
                password: loginPassword
            }
        }, false)
        .then((data) => {
            setError("");
            dispatch(login({id: data.user.id, identifier: loginUser, token: data.jwt}));
            navigate(from, { replace: true });
        })
        .catch((err) => {
            setError("Fehlende oder falsche Zugangsdaten.")
        })
        .finally(() => {
            setLoading(false);
        });
    };

    return <div className={"w-full flex justify-center"}>
        <div className="wrapper flex-col w-80 lg:w-96 mt-20">
            <h3 className="text-2xl text-center text-emerald-700 mb-4">Willkommen bei wishlist.digital</h3>
            <div className="login-wrapper">
                <h2 className="text-lg pb-2">Login</h2>
                <div className="rounded-lg p-4 bg-slate-100 shadow">
                    <FormRow 
                        name="loginUser"
                        label="E-Mailadresse"
                        value={loginUser}
                        setter={setLoginUser}
                        placeholder="marco@email.de"
                    />
                    <FormRow
                        name="loginPassword"
                        label="Passwort"
                        value={loginPassword}
                        setter={setLoginPassword}
                        placeholder="Geh3imn1s5"
                        type="password"
                    />
                    <div className="input-row pt-4 text-right w-full">
                        <PrimaryButton 
                            disabled={loading ||loginUser === "" || loginPassword === ""}
                            value="Einloggen"
                            action={loginAction}
                        />
                    </div>
                </div>
                {error !== "" && <div className="text-center text-red-500 text-md py-2">{error}</div>}
                <div className="mt-2 text-center">Neu hier? Jetzt <div onClick={() => navigate("/register")} className="inline-block text-green-600 hover:cursor-pointer">einen Account erstellen</div></div>
            </div>
        </div>
    </div>
}

export default Login;