// show login or registration possibility
import { loadPartialConfig } from "@babel/core";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import FormRow from "../../components/FormRow";
import PrimaryButton from "../../components/PrimaryButton";
import { fetchData } from "../../services/ApiService";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

const JoinWishlist = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState("");
    const { id, hash } = useParams();
    const [wishlistId, setWishlistId] = useState("");

    const navigate = useNavigate();
    const wishlistIdProvided = id !== undefined;

    let wishlistIdentifier = wishlistId;
    if (wishlistIdProvided) {
        wishlistIdentifier = id;
    }

    useEffect(() => {
        joinAction();
    }, []);

    const joinAction = async () => {
        return fetchData('/wishlist/join', {
            timeout: 5000,
            data: {
                wishlist: wishlistIdentifier,
                password: hash
            }
        })
        .catch((err) => {
            setError("Konnte der Wunschliste nicht beitreten.")
        })
        .finally(() => {
            setIsLoading(false);
        });
    };

    return <div className={"w-full flex justify-center"}>
        <div className="wrapper flex-col w-80 lg:w-96 mt-20">
            <h3 className="text-2xl text-center text-blue-800 mb-4">wishlist.digital</h3>
            <div className="login-wrapper">
                <h2 className="text-lg pb-2">Einer Wunschliste beitreten:</h2>
                <div className="rounded-lg p-4 bg-slate-100 shadow">
                {isLoading && <div className="text-center text-md">Probiere der Wunschliste bei zu treten...</div>}

                {isLoading === false && error === "" && <div>
                        <div>Erfolgreich beigetreten!</div>
                        <div className="input-row pt-4 text-right w-full">
                            <PrimaryButton 
                                value="Zur Wunschliste"
                                action={() => navigate("/wishlist/" + wishlistIdentifier)}
                            />
                        </div>
                    </div>
                }

                {error !== "" && <div className="text-center text-red-500 text-md">{error}</div>}
            
                </div>
                <div className="mt-2 text-center">Zurück zur <div onClick={() => navigate("/")} className="inline-block text-green-600 hover:cursor-pointer">Übersicht</div></div>
            </div>
        </div>
    </div>
}

export default JoinWishlist;